<template>
	<div
		class="full-height"
	>
		<div
			class="full-height"
		>
			<div
				class="justify-space-between"
			>
				<div class="flex-1 bg-white pa-10 mr-10">
					<h6 class="under-line-identify">계정정보</h6>
					<table class="table th-left td-left">
						<col width="120px">
						<col width="auto">
						<col width="120px">
						<col width="auto">
						<tbody>
						<tr>
							<th>영업단 구분 <span class="color-red">*</span></th>
							<td
								v-if="codes.A001"
							>
								<template
									v-for="(code, index) in codes.A001.items"
								>
								<button
									v-if="index < 3"
									:key="code.total_code"

									class="pa-5-10"
									:class="item.agency_type == code.total_code ? 'bg-green' : 'bg-default'"

									@click="item.agency_type = code.total_code; onAgencyType()"
								>{{ code.code_name }}</button>
								</template>

							</td>
							<th>소속 영업점  <v-icon v-if="is_search_upper" class="color-green" @click="onAgencyType">mdi mdi-card-search</v-icon></th>
							<td>
								{{ text_upper }}
							</td>
						</tr>
						<tr>
							<th>아이디</th>
							<td>
								<input
									v-model="item.account_id"
									class="input-box full-width"
									placeholder="아이디를 입력하세요"
									:rules="[$rules.id(item, 'account_id', { min: 4, max: 20})]"
								/>

								<button
									v-if="false"
									class="pa-5-10 bg-blue-light "
								>중복 체크</button>
							</td>
							<th>등록일</th>
							<td class="position-relative">
								<input
									v-model="item.join_date"
									class="input-box full-width"
									placeholder="등록일을 선택하세요"
									readonly
									@click="is_data_pick = !is_data_pick"
								/>
								<v-date-picker
									v-if="is_data_pick"
									v-model="item.join_date"
									no-title
									scrollable
									class="position-absolute box"
									style="top: 45px; left: -5px"
									@change="is_data_pick = false"
								></v-date-picker>
							</td>
						</tr>
						<tr
							v-if="false"
						>
							<th>비밀번호</th>
							<td class="">
								<input
									v-model="item.account_password"
									type="password"
									class="input-box full-width"
									placeholder="비밀번호"
									max="50"
								/>
							</td>

							<th>비밀번호 확인</th>
							<td class="">
								<input
									v-model="item.account_password_confirm"
									type="password"
									class="input-box full-width"
									placeholder="비밀번호를 한번 더 입력하세요"
									max="50"
								/>
							</td>
						</tr>
						<tr>
							<th>이름 <span class="color-red">*</span></th>
							<td>
								<input
									type="text"
									v-model="item.account_name"
									maxlength="20"
									class="input-box full-width"
									placeholder="이름을 입력하세요"
								/>
							</td>
							<th>휴대폰 번호</th>
							<td>
								<input
									type="number"
									v-model="item.account_phone_number"
									:ruels="[rules.max(item, 'account_phone_number', 12)]"
									class="input-box full-width"
									placeholder="휴대폰번호를 입력하세요"
								/>
							</td>
						</tr>
						</tbody>
					</table>


					<div class="mt-10">
						<h6>정산 정보</h6>
						<table class="table th-left td-left">

							<col width="120px">
							<col width="auto">
							<col width="120px">
							<col width="auto">
							<tbody>
							<tr
								v-if="item.agency_type == 'A001001'"
							>
								<th> {{ text_fee_rate }} <span class="color-red">*</span></th>
								<td>
									<div
										class="justify-start"
									>
										<div class="flex-1 text-left justify-space-between">
											카드 결제
											<span>
											<input
												v-model="item.sales_fee"
												type="number"
												:rules="[rules.demical(item, 'sales_fee', { min: 2, max: 2})]"
												class="box pa-5-10 width-fee "
											/> %
												</span>
										</div>
									</div>
									<div
										class="justify-start mt-10"
									>
										<div class="flex-1 text-left justify-space-between">
											무통장 입금
											<span>
											<input
												v-model="item.sales_fee_bank"
												type="number"
												:rules="[rules.demical(item, 'sales_fee_bank', { min: 2, max: 2})]"
												class="box pa-5-10 width-fee "
											/> %
												</span>
										</div>
									</div>
								</td>
								<th>정산주기</th>
								<td class="text-left">영업일 기준 / 월 정산</td>
							</tr>
							<tr
								v-else
							>
								<th>정산주기</th>
								<td class="text-left" colspan="3">영업일 기준 / 월 정산</td>
							</tr>
							<tr>
								<th>은행</th>
								<td>
									<select
										v-model="item.bank_code"
										class="input-box"
									>
										<option :value="''">은행을 선택하세요</option>
										<option
											v-for="code in codes.B001.items"
											:key="code.total_code"
											:value="code.total_code"
										>{{ code.code_name}}</option>
									</select>
								</td>
								<th>계좌번호</th>
								<td>
									<input
										v-model="item.bank_account"
										type="number"
										class="input-box full-width"
										:rules="[rules.max(item, 'bank_account', 25)]"
										placeholder="정산 계좌 번호"
									/>
								</td>
							</tr>
							<tr>
								<th>예금주</th>
								<td>
									<input
										v-model="item.bank_holder"
										class="input-box full-width"
										placeholder="정산 계좌 예금주"
										maxlength="15"
									/>
								</td>
								<th>이메일</th>
								<td>
									<input
										v-model="item.bank_email"
										type="email"
										class="input-box full-width"
										placeholder="세금계산서 발행 이메일"
										maxlength="50"
									/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="flex-1 bg-white pa-10">
					<h6 class="under-line-identify">상점 정보</h6>
					<table class="table th-left td-left">
						<col width="120px">
						<col width="auto">
						<col width="120px">
						<col width="auto">
						<tbody>
							<tr>
								<th>매입몰 여부</th>
								<td colspan="3">
									<label
										@click="item.sales_type == '1' ? item.sales_type = '' : item.sales_type = '1'"
									>
										<v-icon
											v-if="item.sales_type == '1'"
											class="color-primary"
										>mdi mdi-checkbox-marked</v-icon>
										<v-icon
											v-else
										>mdi mdi-checkbox-blank-outline</v-icon>
										매입몰
									</label>
								</td>
							</tr>
							<tr>
								<th>상점명 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										type="text"
										v-model="item.shop_name"
										maxlength="50"
										class="input-box-inline"
										placeholder="상점명을 입력하세요"
									/>
								</td>
							</tr>
							<tr
								v-if="item.agency_type == 'A001003'"
							>
								<th>상점 로고</th>
								<td colspan="3">

									<div>
										<label
											class="box pa-10 justify-space-between"
										>
											{{ logo_img_name }}
											<v-icon
												class="color-icon"
											>mdi mdi-image</v-icon>

											<input_file
												v-show="false"
												accept="image/*" @change="setFile2"
											/>
										</label>
									</div>

									<div
										v-if="item_upload_logo_img.src"
										class="flex-row mt-10"
									>
										<div
											v-if="item_logo_img.type.indexOf('image') > -1"
											class="flex-1" style="position: relative"
										>
											<img
												:src="item_upload_logo_img.src"
												style="max-width: 180px"
											/>
											<button class="item_close" style="background-color: black">
												<v-icon
													@click="item_upload_logo_img = {}"
												>mdi-close</v-icon>
											</button>
										</div>
										<div class="flex-3 flex-column justify-center ml-10">
											<p>{{  item_upload_logo_img.name }}</p>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<th>사업자 구분 <span class="color-red">*</span></th>
								<td colspan="3">
									<button
										v-for="code in codes.B002.items"
										:key="code.total_code"

										class="pa-5-10"
										:class="item.business_type == code.total_code ? 'bg-green' : 'bg-default'"

										@click="item.business_type = code.total_code"
									>{{ code.code_name }}</button>
								</td>
							</tr>
							<tr
								v-if="item.business_type == 'B002002'"
							>
								<th>사업자등록번호 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										type="number"
										v-model="item.front"
										:ruels="[rules.max(item, 'front', 10)]"
										class="input-box-inline"
										placeholder="사업자 등록 번호"
									/>
								</td>
							</tr>
							<tr
								v-if="item.business_type == 'B002001'"
							>
								<th>사업자등록번호 <span class="color-red">*</span></th>
								<td>
									<input
										type="number"
										v-model="item.front"
										:ruels="[rules.max(item, 'front', 10)]"
										class="input-box-inline"
										placeholder="사업자 등록 번호"
									/>
								</td>
								<th>법인등록번호 <span class="color-red">*</span></th>
								<td>
									<input
										type="number"
										v-model="item.back"
										:ruels="[rules.max(item, 'back', 13)]"
										class="input-box-inline"
										placeholder="법인 등록 번호"
									/>
								</td>
							</tr>
							<tr
								v-if="item.business_type == 'B002003'"
							>
								<th>주민등록 번호 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										v-model="item.front"
										type="number"
										:ruels="[rules.max(item, 'front', 6)]"
										class="input-box-inline mr-10"
										placeholder="주민등록 앞번호"
									/>
									<input
										v-model="item.back"
										type="password"
										maxlength="7"
										class="input-box-inline"
										placeholder="주민등록 뒷번호"
									/>
								</td>
							</tr>
							<tr>
								<th>사업장 주소 <span class="color-red">*</span></th>
								<td colspan="3">

									<input
										v-model="item.shop_zip_code"

										class="input-box-inline mr-10"
										type="text" placeholder="우편번호"
										readonly

										@click="daumPost('company')"
									>

									<button
										class="box pa-5-10 bg-identify"
										@click="daumPost('company')"
									>주소 검색</button>

									<input
										v-model="item.shop_address"

										class="input-box full-width mt-10" type="text" placeholder="주소"
										readonly

										@click="daumPost('company')"
									>
									<input
										v-model="item.shop_address_detail"

										class="input-box full-width mt-10" type="text" placeholder="상세주소를 입력하세요."
										maxlength="50"
									>
								</td>
							</tr>
							<tr
								v-if="false"
							>
								<th>보유 PG 정보</th>
								<td colspan="3">
									<select
										v-model="item.pg_code"
										class="pa-5-10 mr-10"
									>
										<option value="">PG사</option>
										<option
											v-for="(code, index) in codes.P004.items"
											:key="code.total_code + '_' + index"
											:value="code.code_value"
										>{{ code.code_name }}</option>
									</select>

									<input v-model="item.pgMerchNo" class="pa-5-10 mr-10 box" placeholder="가맹점 ID" maxlength="20" />

									<input v-model="item.pgMerchName" class="pa-5-10 mr-10 box" placeholder="터미널 ID" maxlength="15" />

									<input v-model="item.pg_fee" class="pa-5-10 mr-10 box" placeholder="PG 결제 수수료" :rules="[$rules.demical(item, 'pg_fee', {min: 2, max:2})]"/>

								</td>
							</tr>
							<tr
								v-if="item.agency_type == 'A001003'"
							>
								<th>1회 결제 한도</th>
								<td colspan="3">

									<input
										v-model="item.payment_limit"
										class="input-box full-width"
										placeholder="1회 결제 한도 금액을 설정하세요"
										type="number"
										:rules="[$rules.id(item, 'payment_limit', { min: 4, max: 8})]"
									/>
								</td>
							</tr>
							<tr
								v-if="item.agency_type == 'A001003'"
							>
								<th>쇼핑몰 도메인</th>
								<td>

									<input
										v-model="item.shop_link"
										class="input-box full-width"
										placeholder="도메인을 입력하세요"
									/>
								</td>
								<th>SMS</th>
								<td>
									<select
										v-model="item.sms_uid"
										class="input-box"
									>
										<option value="">선택하세요</option>
										<option
											v-for="(sms, sms_index) in items_sms"
											:key="'sms_' + sms_index"
											:value="sms.uid"
										>{{ sms.sms_name}}</option>
									</select>
								</td>
							</tr>
							<tr
								v-if="false"
							>
								<th>결제 PG</th>
								<td colspan="3">
									<div>
										<label
											class="pa-10 display-inline box radius-10 mr-10"
											:class="{'bg-success': item.sales_pg_uid === ''}"
										>
											<input
												v-model="item.sales_pg_uid"
												type="radio"
												value=""
											/> 보유 PG
										</label>
										<label
											v-for="(pg, index) in items_pg_list"
											:key="'pg_' + index"
											class="pa-10 display-inline box radius-10 mr-10"
											:class="{'bg-success': item.sales_pg_uid == pg.uid }"
										>
											<input
												v-model="item.sales_pg_uid"
												type="radio"
												:value="pg.uid"
											/> {{ pg.pg_name }} {{ pg.pg_fee }}%
										</label>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div
			class="justify-center"
		>
			<button
				class="bg-identify pa-10-20 mr-10"
				@click="save"
			>저장</button>
			<button
				class="box pa-10-20"
				@click="toList"
			>목록</button>
		</div>
		<DaumPost
			:overlay="is_post"
			:config="daum_config"
			@callBack="addPost"
		></DaumPost>
		
		<Modal
			:is_modal="is_bussiness"
			:option="modal_option"
			:width="'560px'"
			:height="'480px'"

			@cancel="is_bussiness = false"
			@close="is_bussiness = false"
			@click="setUpper"
		>
			<div
				slot="modal-content"
			>
				<div>
					<input
						v-model="item_search.agency_name"
						class="input-box-inline mr-10"
						placeholder="영업점 명을 입력하세요"
					/>
					<button
						@click="getUpper"
						class="box pa-5-10 bg-base"
					>조회</button>
				</div>
				<table
					class="table mt-10"
				>
					<thead>
						<tr>
							<th>구분</th>
							<th>영업점 명</th>
							<th>선택</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(upper, index) in items_upper"
							:key="'upper_' + index"
							:class="{on: upper.uid == item_upper_select.uid}"
						>
							<td>{{ upper.agency_type_name}}</td>
							<td class="text-left">{{ upper.agency_name}}</td>
							<td><button @click="selectUpper(upper)" class="btn-blue pa-5-10">선택</button></td>
						</tr>
					</tbody>
				</table>
			</div>
		</Modal>
	</div>
</template>

<script>

import DaumPost from "@/components/Daum/DaumPost";
import input_file from '@/components/InputFile'
import Modal from "../../components/Modal";
export default {
	name: 'AgencyItem'
	,
	components: {Modal, DaumPost, input_file},
	props: ['Axios', 'user', 'codes', 'rules', 'date']
	,data: function(){
		return {
			program: {
				name: '영업점 등록'
				, top: true
				, title: true
				, bottom: false
			}
			,item: {
				agency_type: this.$route.params.type ? this.$route.params.type : 'A001003'
				, business_type: 'B002001'
				, bank_code: ''
				, join_date: this.date.getToday('-')
				, agency_upper: this.user.admin_id
				, sales_fee: 0
				, sales_fee_bank: 0
				, pg_type: 'P001001'
				, sales_pg_uid: null
				, pg_code: ''
				, agency_upper_name: ''
				, sales_type: ''
				, sms_uid: ''
			}
			,is_data_pick: false
			,is_modal: false
			,is_post: false
			,daum_config: {
				width: '360px'
			}
			,items_upper: []
			, upload_files: []
			, item_logo_img: ''
			, item_upload_logo_img: {}
			, items_pg_list: []
			, is_bussiness: false
			, items_distributor: []
			, modal_option: {
				top: true
				,title: '소속 영업점 조회'
				,bottom: true
			}
			, item_upper_company: {
				agency_upper: ''
				, agency_name: ''
			}
			, item_upper_distributor: {
				agency_upper: ''
				, agency_name: ''
			}
			, item_search: {
				agency_name: ''
			}
			, item_upper: {
				uid: ''
				, agency_name: ''
			}
			, item_upper_select: {

			}
			, items_sms: []
		}
	}
	,computed: {

		logo_img_name: function(){
			let name = '로고 이미지'
			return name
		}
		, text_fee_rate: function(){
			let t = '공급가 마진'
			if(this.item.agency_type == 'A001003'){
				t = '공급가 마진'
			}
			return t
		}
		, text_upper: function(){
			let t = ''

			if(this.item_upper.uid){
				t = this.item_upper.agency_name
			}

			return t
		}
		, is_search_upper: function(){
			let t = false
			if(this.item.agency_type != 'A001001'){
				t = true
			}
			return t
		}
	}
	, methods: {
		save: async function(){
			try{
				this.$bus.$emit('on', true)

				this.$set(this.item, 'item_logo_img', this.item_logo_img)

				this.$set(this.item, 'sales_pg_list', JSON.stringify(this.items_pg_list))

				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postAgency'
					,data: this.item
				})
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.toList()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		,daumPost: function () {
			this.is_post = true
		}
		, addPost: function (call) {

			this.$set(this.item, 'shop_zip_code', call.zonecode)
			this.$set(this.item, 'shop_address', call.address)

			this.is_post = false
		}
		, toList: function(){
			this.$router.back()
		}
		, getUpper: async function(){
			try{
				this.item_upper_select = {}
				const result = await this.$request.init({
					method: 'get'
					,url: 'admin/getUpper'
					,data: {
						agency_type: this.item.agency_type
						, agency_name: this.item_search.agency_name
					}
				})
				if(result.success){
					this.items_upper = result.data.result
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setFile: function(e){
			console.log('setFile', e)
			console.log(e[0].size / 1024)

			let file_count = this.files.length + e.length
			if(file_count > this.file_max){
				this.$emit('notify', { type: 'error', message: this.$language.common.error_file_limit})
				return false
			}

			console.log('for files')
			let self = this
			for(let file of e){
				console.log(`file` , file)
				this.upload_files.unshift(file)
				const reader = new FileReader()
				let data = {
					name: file.name
					, size: file.size
					, type: file.type
				}

				reader.onload = function(e){
					console.log('reader.onload')
					data.src = e.target.result
					self.files.unshift(data)
				}

				reader.readAsDataURL(file)
			}
		}

		, setFile2: function(e){
			console.log('setFile2', e)

			let self = this
			for(let file of e){
				console.log(`file` , file)
				this.item_logo_img = file
				const reader = new FileReader()
				let data = {
					name: file.name
					, size: file.size
					, type: file.type
				}

				reader.onload = function(e){
					console.log('reader.onload')
					data.src = e.target.result
					self.item_upload_logo_img = data
				}

				reader.readAsDataURL(file)
			}
		}
		, removeFile: function(index){
			this.files.splice(index, 1)

			for(const [key] of Object.entries(this.upload_files)) {
				delete this.item['upload_files' + key]
			}
			this.upload_files.splice(index, 1)
		}
		, getPgList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAvailablePgList'
					,data: {
						agency_type: this.item.agency_type
						, agency_upper: this.item.agency_upper
					}
				})
				if(result.success){
					this.items_pg_list = result.data
					this.item.sales_pg_uid = null
				}else{
					this.items_pg_list = []
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setUpper: function(){
			this.item_upper = this.item_upper_select
			this.item.agency_upper = this.item_upper.uid
			this.is_bussiness = false
		}
		, selectUpper: function(upper){
			this.item_upper_select = upper
		}
		, onAgencyType: function(){

			this.item_upper = {}

			if(this.item.agency_type == 'A001001'){
				this.item.agency_upper = this.user.admin_id
			}else{
				this.item.agency_upper = ''
				this.is_bussiness = true
			}

			this.items_upper = []
		}
		, getSmsList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getSmsList'
					,data: {
						agency_type: this.item.agency_type
					}
				})
				if(result.success){
					this.items_sms = result.data
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		if(this.user.role_group == 'agency'){
			this.item.agency_type = this.codes.A001.items[2].total_code
		}
		this.getSmsList();
	}
}
</script>

<style>
.width-fee { width: 60px !important; text-align: right;}
.v-btn__content { color: #333 !important;}

.v-file-input__text--placeholder {
	color: #bbb !important;
	font-size: 14px;
}

.theme--light.v-icon {
	color: #bbb;
}

.item_close {
	position: absolute; right: 10px; top: 10px
}
</style>